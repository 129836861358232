.modal-content {
  padding: 5rem;
  position: relative;
}
.modal-header, .modal-footer {
  border: 0;
}
.modal-header {
  .btn-close {
    filter: invert(1);
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 20px;
    height: 20px;
    background-size: 20px;
  }
}
.modal-body {
  margin: 0 0 2.5rem;

  p {
    font-weight: 300;
  }
}