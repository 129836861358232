.btn {
  min-width: 12rem;
  font-family: $font-family-sans-serif;
  padding: 1.3rem 1.6rem 1.2rem;
  border: 0;
  text-transform: uppercase;
  font-weight: 500;

  i {
    line-height: 1.4;
    margin-right: 1.2rem;
  }

  [class*="icon-"] {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }

  &.btn-primary {
    background-color: $primary;
    color: $blue-1;
    border-color: $primary;
  }

  &.btn-warning {
    background-color: $warning;
  }

  &.btn-default {
    border-color: $gray-400;
    color: $dark-gray-100;
    background-color: $white;
  }

  &:hover,
  &:focus,
  &:active:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.gray-outline:not(:hover):not(:active) {
    border-color: $gray-900;
    color: $gray-900;
  }

  &.btn-sm {
    min-width: 8rem;
    padding: 0.36rem 0.5rem 0.28rem;
    font-size: 1rem;
    letter-spacing: 1px;
  }

  &.btn-lg {
    padding: 0.8rem 2rem;
    font-size: 1.4rem;
    border-radius: 0.3rem;
  }

  &.btn-poppins {
    font-family: $headings-font-family;
    font-size: 1.76rem;
  }
  &.btn-light {
    color: $blue-1;
    background-color: $white;
    border-color: $white;
  }
  &.btn-secondary {
    background-color: $dark-1;
    background: radial-gradient(circle, $dark-2 0%, $dark-1 100%);
    border-color: $dark-1;
  }
  &.btn-danger {
    color: $danger;
    background-color: $dark-1;
    border-color: $dark-1;
  }
  &.btn-icon {
    height: 4.8rem;
    min-width: 4.8rem;
    padding: 0;
    @extend .d-flex, .align-items-center, .justify-content-center;

    i {
      margin: 0;
      font-size: 1.6rem;
    }
  }
}

.btn-outline-primary {
  &:hover {
    color: $white;
  }
}