@font-face {
  font-family: 'icomoon';
  // src: 
    // url('../fonts/icomoon.eot') format("embedded-opentype"),
    // url('../fonts/icomoon.ttf') format("truetype"),
    // url('../fonts/icomoon.woff') format("woff"),
    // url('../fonts/icomoon.svg') format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


$icons:(
  arrow-up:"\e929",
  arrow-down:"\e928",
  settings:"\e927",
  folder:"\e926",
  add:"\e923",
  blocked:"\e924",
  content:"\e925",
  arrow-left:"\e900",
  arrow-right: "\e901",
  bell: "\e902",
  building: "\e903",
  calendar: "\e904",
  call: "\e905",
  task: "\e906",
  user-circle: "\e907",
  trash: "\e908",
  contract: "\e909",
  eye-close: "\e90a",
  dots: "\e90b",
  edit: "\e90c",
  eye: "\e90d",
  edit-border: "\e90e",
  fb: "\e90f",
  file-pdf: "\e910",
  filter: "\e911",
  graph: "\e912",
  grid: "\e913",
  handshake: "\e914",
  linkedin: "\e915",
  location: "\e916",
  mail: "\e917",
  message: "\e918",
  project: "\e919",
  search: "\e91a",
  cross: "\e91b",
  chevron-up: "\e91c",
  trash-fill: "\e91d",
  twitter: "\e91e",
  user: "\e91f",
  chevron-down: "\e920",
  vendor: "\e921",
  check: "\e922",
);
@each $name, $icon in $icons {
  .icon-#{$name}:before {
    content: $icon;
  }
}
