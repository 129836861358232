img {
  max-width: 100%;
}

a {
  color: $white;
  &:hover {
    color: $white;
  }
}

// label {
//   font-size: 1.44rem;
//   white-space: nowrap;
// }

::selection {
  background: $dark;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

::placeholder {
  color: $dark-gray-100 !important;
  opacity: 1 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $dark-gray-100 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $dark-gray-100 !important;
}

.cursor-p {
  cursor: pointer;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.table-card {
  border-radius: 1.2rem;
  overflow: hidden;
  background-color: $black;
  background: radial-gradient(circle, $dark 0%, $black 100%);
  padding: 2rem;
}

.App {
  min-height: 100vh;
  @extend .d-flex, .flex-column;
}

.form-wrapper {
  padding: 20px;
  background: linear-gradient(90deg, #E30614 0%, #a2050e 100%);
  border-radius: 1.2rem;
  width: 500px;
  height: 400px;
  justify-content: center;
  align-items: center;
  display: block;
  // box-shadow: 7px 12px #b1ddd0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px;
}

.icon {
  position: absolute;
  top: 1.4rem;
  right: 1rem;
  font-size: 1.25rem;
  display: flex;
  cursor: pointer;

  [class*="icon-"] {
    display: none;

    &.active {
      display: block;
    }
  }
}

.full-wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  transition: all 0.3s ease;

  @include media-breakpoint-down(lg) {
    padding-left: 0;
  }
}
.jllheading{
  color: #fff;
}

.left-wrapper {
  position: relative;
  min-height: 100vh;
  width: 30%;
  background: linear-gradient(90deg, #E30614 0%, #a2050e 100%);
  justify-content: center;
  align-items: center;
  display: flex;
}
.spinner-wrap {
  width: calc(100% - 300px);
  height: 100%;
  position: absolute;
  background-color: rgba(255,255,255,.8);
  right: 0;
  top: 0;
  z-index: 9999;
}

.spinner-border {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
  color: #6c757d96!important;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99999;
  margin-left: -2.5rem;
  margin-top: -2.5rem;
}

.spinner-border {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
  color: #6c757d!important;
  
}

.k-grid a :hover{
  color: darkblue;
}
a:hover {
  color: darkblue;
}

.logo{
  padding: 20px;
}

.table-card1{
  border-radius: 1.2rem;
  overflow: hidden;
 border:solid 1px #000;
  padding: 2rem;
}
.table-content{
  color: #000;
}

.right-wrapper {
  position: relative;
  width: 70%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  background: radial-gradient(circle, #49494d 0%, #3f4044 100%);
}

.jltitle{
  color: #000;
}
.btn-danger{
  color: #E30614;
  box-shadow: none;
    border-color: currentColor;
    
    background-color: transparent;
}

.content-wrapper {
  padding: 2.4rem 2rem 2.4rem 4rem;
  flex: 1;
  background-color: #fff;
  // background: radial-gradient(circle, $dark-1 0%, $dark 100%);
  color: $white;
}

.main {
  position: relative;
}

.wrapper {
  position: relative;
  min-height: 100vh;
  @extend .d-flex, .flex-column;
  padding-left: 300px;
  background-color: $gray;
  transition: all 0.3s ease;

  @include media-breakpoint-down(lg) {
    padding-left: 0;
  }
}
.linkText {
  color: $blue-1;
  cursor: pointer;
}

.content-header {
  padding-bottom: 3rem;
  @extend .d-flex, .justify-content-between;

  .title {
    font-size: 2.6rem;
    font-weight: 500;
  }
  .sub-title {
    font-size: 1.8rem;
    font-weight: 500;
  }
}

.sidebar-toggle {
  .wrapper {
    padding-left: 90px;
  }
}
.card {
  background-color: $dark-2;
  border-radius: 1.2rem;
  margin-bottom: 2rem;

  .card-body {
    padding: 3rem;
  }
  &.player-card {
    background-color: $white;
    .card-body {
      padding: 1rem;
      @extend .d-flex, .align-items-center;
    }
    .icon {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      font-weight: 600;
      letter-spacing: -0.2rem;
      @extend .d-flex, .align-items-center, .justify-content-center;
      &.blue {
        color: $blue-1;
        background-color: rgba($blue-1, 0.25);
      }
      &.orange {
        color: $orange;
        background-color: rgba($orange, 0.25);
      }
      &.purple {
        color: $purple;
        background-color: rgba($purple, 0.25);
      }
    }
    .content {
      color: $black;
      font-weight: 600;
      margin: 0 auto;
      font-size: 1.5rem;

      span {
        margin-left: 0.5rem;
        font-weight: 400;
      }
    }
  }
}
.dashboard-card {
  @extend .d-flex, .align-items-center;
  .card-icon {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    margin-right: 2rem;
    font-size: 3.2rem;
    @extend .d-flex, .align-items-center, .justify-content-center;
    &.blue {
      background-color: $primary;
      color: $blue-1;
    }
    &.success {
      background-color: $success;
      color: $light-green;
    }
    &.light {
      background-color: $dark-4;
      color: $white;
    }
  }
  .card-content {
    .title {
      color: $gray;
    }
    .count {
      font-size: 3rem;
      font-weight: 500;
    }
  }
}

.badge {
  border-radius: 0.3rem;
  font-size: 1.4rem;
  width: 24px;
  height: 24px;
  @extend .d-flex, .justify-content-center, .align-items-center;

  &.badge-sm {
    font-size: 1.15rem;
    width: 20px;
    height: 20px;
  }
  &.badge-secondary {
    background-color: $dark-2;
  }
}
.text-gray {
  color: $gray;
}
@media (min-width: 1800px) {
  .row-modify-10 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    > [class*="col-"],
    > [class*="col"] {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .row-modify-15 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    > [class*="col-"],
    > [class*="col"] {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }

  .row-modify-20 {
    margin-left: -1rem;
    margin-right: -1rem;

    > [class*="col-"],
    > [class*="col"] {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.sorting-icon {
  font-size: 0.9rem;
  display: inline-flex;
  margin-left: 0.75rem;
  color: rgba($black, 0.4);

  [class*="icon-"] {
    position: relative;

    &.active {
      color: $black;
    }
  }

  .icon-arrow-down {
    top: -0.4rem;
    left: 0.05rem;
  }

  .icon-arrow-up {
    top: 0.4rem;
    left: -0.05rem;
  }
}

.checkbox-table {
  .table {
    > thead {
      tr {
        border: 0;

        th {
          border: 0;
          font-family: $headings-font-family;
          font-weight: 500;
          line-height: 1.5;
          font-size: 1.44rem;
        }
      }
    }

    td {
      border: 0;
    }
  }
}

.dropdown-toggle {
  &.btn {
    &.btn-primary {
      padding: 0;
      min-width: auto;
      background: transparent;
      border: 0;
      box-shadow: none;

      &::after,
      &::before {
        content: none;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.dashed-boder {
  border: 1px dashed $gray-100;
}

.btn-group-wrap {
  button {
    + button {
      margin-left: 1.6rem;
    }
  }
}

.approval-modal {
  .custom-file-input {
    min-height: 4rem;
    max-width: 100%;
    width: 22.2rem;
    border: 0.1rem solid $gray-100;
    border-radius: 0.8rem;
    padding: 0.4rem;
    cursor: pointer;
  }
}
