@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

html,
body {
  min-height: 100vh;
}

body {
  overflow-x: hidden;
}

p {
  line-height: 1.6;
}
label{
  font-family: 'Poppins';
  font-weight: 500;
}

.fs-13 {
  font-size: 1.3rem !important;
}

.fs-14 {
  font-size: 1.4rem !important;
}

.fs-15 {
  font-size: 1.5rem !important;
}

.fs-16 {
  font-size: 1.6rem !important;
}

.fs-18 {
  font-size: 1.8rem !important;
}

.fs-20 {
  font-size: 2rem !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

@include media-breakpoint-down(md) {
  :root {
    font-size: 55%;
  }
}