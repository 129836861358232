$font-family-sans-serif: 'Poppins', sans-serif;
$headings-font-family: 'Poppins', sans-serif;
$headings-line-height: 1.5;
$headings-font-weight: 600;
$font-size-root: 62.5%;
$font-size-base: 1.6rem;
$line-height-base: 1.4;
$paragraph-margin-bottom: 1.6rem;
$enable-rfs: false;
$spacer: 1.6rem;
$grid-gutter-width: 2.4rem;
$link-decoration: none;
$h1-font-size: 3.6rem;
$h2-font-size: 3.2rem;
$h3-font-size: 2.8rem;
$h4-font-size: 2.4rem;
$h5-font-size: 2rem;
$h6-font-size: 1.6rem;
$white:#FFFFFF;
$black : #000000;
$dark : #1c1c1e;
$dark-1 : #393a3e;
$dark-2 : #46474b;
$dark-3 : #48494d;
$dark-4 : #505053;
$dark-5 : #47484c;
$dark-6 : #525252;
$dark-7 : #393939;
$gray: #959596;
$body-color: $black;
$secondary: $black;
$light-blue-1: #F7F8FA;
$light-blue-2: #c5e9f8;
$blue: #2d395e;
$blue-1: #7193ff;
$primary : $blue;
$gray-100: #DBDBDB;
$gray-200: #C3CADA;
$gray-300: #e2e2e2;
$gray-400: #dde0e9;
$gray-500: #FCF9F9;
$gray-600: #FCFCFC;
$gray-700: #e6e6e6;
$gray-800: #E5E5E5;
$dark-gray-100:#48494b;
$dark-gray-200: #282829;
$red: #ff7572;
$orange : #fe9850;
$purple : #8967b2;
$danger: $red;
$green : #2b4737;
$success : $green;
$light-green:#71ffae;
$text-muted: $dark-gray-100;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1.6rem;
$input-line-height : 1;
$input-color: $black;
$input-bg: $white;
$input-font-size: 1.6rem;
$input-border-color: $gray-100;
$input-border-radius: 0.8rem;
$input-box-shadow: 0 2px 7px rgba($black, .05);
$input-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-font-weight: 400;
$input-focus-border-color: none;
$form-label-color: $black;
$form-label-font-weight: 400;
$form-select-focus-border-color:none;
$form-select-border-radius: 0.6rem;
$form-select-focus-box-shadow: none;
$form-label-margin-bottom: .8rem;
$form-label-font-size: 1.44rem;

$btn-padding-y: .8rem;
$btn-padding-X: 1.5rem;
$btn-font-weight: 400;
$btn-border-radius: 0.8rem;
$btn-font-size: 1.37rem;
$btn-focus-box-shadow: none;

$modal-content-border-radius: 0.8rem;
$modal-content-inner-border-radius: 0.6rem;

$table-cell-padding-y: 1.5rem;
$table-cell-padding-x: 1.2rem;
$table-bg: transparent;
$table-color : $white;
$table-th-font-weight : 500;
// $table-border-width: 0;

$form-check-input-focus-box-shadow: none;
$form-check-input-width: 1.2em;

$font-Poppins: "Poppins", sans-serif;

//modal
$modal-content-bg : $dark-7;
$modal-inner-padding : 0;
$modal-content-color : $white;
$modal-header-border-color : rgba($dark, 0.3)