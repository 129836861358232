.sidebar-wrapper {
  position: fixed;
  background-color: $dark;
  background: linear-gradient(90deg, #E30614 0%, #b5151f 100%);
  top: 0;
  bottom: 0;
  z-index: 1000;
  width: 300px;
  transition: all 0.3s ease;
  @extend .d-flex, .flex-column, .justify-content-between;
  .sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    transition: all 0.3s ease;
    @include media-breakpoint-down(lg) {
      width: 0;
    }

    .brand {
      color: $white;
      padding: 2.6rem;
      white-space: nowrap;
      font-size: 2.4rem;
      font-weight: 500;
      transition: all 0.3s ease;
    }

    .image {
      max-width: 6.5rem;
      margin: 2.64rem auto 2.4rem;
    }
    .sidenav {
      border-top: 1px solid rgba($white, 0.25);
      @extend .list-unstyled, .m-0;
      padding: 2.5rem 0;
      li {
        padding: 0 2rem 0.5rem;
        a {
          @extend .d-flex, .align-items-center;
          padding: 1.5rem;
          color: #fff;
          border-radius: 0.8rem;
          position: relative;
          overflow: hidden;

          .nav-icon {
            font-size: 1.5rem;
            min-width: 2rem;
          }
          .nav-text {
            flex: 1 1 100%;
            line-height: 1;
            font-weight: 500;
            white-space: nowrap;
            transition: all 0.3s ease;
            margin-left: 1.5rem;
            transition: all 0.3s ease;
          }
        }
        &.active, &:hover {
          a {
            background-color: #ffffff;
            color: rgb(0, 0, 0);
            &::before {
              content: "";
              position: absolute;
              left: 0;
              width: 4px;
              background:  $blue-1;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .bottom {
    padding: 0 2rem 0.5rem;
    overflow: hidden;
    transition: all 0.3s ease;
    .bottom-link {
      .btn {
        background-color: $dark-1;
        background: linear-gradient(90deg, $dark-1 0%, $dark-gray-100 100%);
        color: $white;
        width: 100%;
        padding: 1.53rem 1.7rem;
        @extend .d-flex;
        transition: all 0.3s ease;

        i {
          transform: rotate(180deg);
          margin-right: 1rem;
        }
        span {
          transition: all 0.3s ease;
          white-space: nowrap;
        }
      }
    }
    p {
      font-size: 1.15rem;
      color: $gray;
      margin-top: 2rem;
      text-align: center;
      white-space: nowrap;
      transition: all 0.3s ease;
    }
  }
  .toggle-btn {
    position: absolute;
    top: 2.64rem;
    width: 35px;
    height: 27px;
    right: -35px;
    cursor: pointer;
    @extend .d-flex, .align-items-center, .justify-content-center;
    ul {
      width: 28px;
      height: 28px;
      position: relative;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        position: absolute;
        left: 0;
        transform: translate(0, -50%);
        width: 28px;
        height: 4px;
        border-radius: 3px;
        background: $blue-1;
        opacity: 1;
        transition: transform 0.3s, top 0.3s, opacity 0.3s;
        transition-delay: 0.2s, 0.2s, 0.2s;

        &:nth-child(1) {
          top: 25%;
          background: $blue-1;
        }
        &:nth-child(2) {
          top: 50%;
          background: rgba($blue-1, 0.75);
          width: 23px;
        }
        &:nth-child(3) {
          top: 75%;
          background: rgba($blue-1, 0.5);
          width: 12px;
        }
      }
    }

    &.active {
      ul {
        li {
          &:nth-child(1) {
            top: 50%;
            transform: translate(0, -50%) rotate(-45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
            background: $blue-1;
            width: 28px;
          }
        }
      }
    }
  }
}

.sidebar-toggle {
  .sidebar-wrapper {
    width: 90px;

    .sidebar {
      .brand {
        width: 0;
        opacity: 0;
      }
    }
    .bottom {
      .bottom-link {
        .btn {
          min-width: auto;
          span {
            width: 0;
            opacity: 0;
          }
        }
      }
      p {
        width: 0;
        opacity: 0;
      }
    }
    .toggle-btn {
      ul {
        li {
          &:nth-child(1) {
            top: 50%;
            transform: translate(0, -50%) rotate(-45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
            background: $blue-1;
            width: 28px;
          }
        }
      }
    }
  }
}
