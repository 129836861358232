.loginPage {
  min-height: 100vh;
  background-color: $dark;
  background: radial-gradient(circle, $dark-1 0%, $dark 100%);
  @extend .d-flex, .justify-content-center, .align-items-center;

  .card {
    max-width: 40rem;
    width: 100%;
    margin: 0;

    .title {
      color: $white;
      text-align: center;
      font-size: 2.4rem;
    }
  }
  p {
    color: $white;
    font-size: 1.2rem;
    word-break: break-word;
    margin: 2rem 0;
  }
  
}
.admintest{
  padding: 35px;
}