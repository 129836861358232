
.form-group, .input-group {
  .form-control {
    color: $white;
    background: $dark-2;
    border: 0;
    padding: 1.15rem 1.6rem;
    font-size: 1.4rem;

    &::placeholder {
      color: $gray!important;
      opacity: 1;
    }
    
    &:-ms-input-placeholder { 
     color: $gray!important;
    }
    
    &::-ms-input-placeholder { 
     color: $gray!important;
    }
  }
}
.input-group {
  .input-group-text {
    background-color: $dark-2;
    color: $gray;
    border: 0;
  }
}