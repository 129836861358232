
.table {
  margin: 0;

  > thead {
    tr {

      th {
        font-size: 1.44rem;
        white-space: nowrap;
        border: 0;
      }
    }
  }
  > tbody {
    tr {
      td {
        font-size: 1.44rem;
        font-weight: 300;
        border: 0;
      }
      &:nth-child(odd) {
        background-color: $dark-3;
        background: radial-gradient(circle, $dark-2 0%, $dark-1 100%);

        td {
          &:first-of-type{
            border-top-left-radius: 0.8rem;
            border-bottom-left-radius: 0.8rem;
          }
          &:last-of-type {
            border-top-right-radius: 0.8rem;
            border-bottom-right-radius: 0.8rem;
          }
        }
      }
    }
  }

  > :not(:first-child) {
    border-top: 0;
  }

  td {
    // border-bottom: 1px solid rgba($gray-200, 0.4);

    a {
      text-decoration: underline;
    }
  }
}