.toggle-switch {
  @extend .d-flex, .align-items-center;
  span {
    color: $gray;
    font-size: 1.4rem;
    font-weight: 400;
    margin-right: 0.6rem;
  }
  input[type=checkbox]{
    display: none;

    &:checked {
      + .toggle-text {
        background-color: $success;
        border: 1px solid $success;

        &:after {
          left: calc(100% - 1px);
          transform: translateX(-100%);
          background: $light-green;
        }
        &:active{
          &:after {
            width: 10px;
          }
        }
      }
    }
  }
  .toggle-text{
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 18px;
    background: $dark-6;
    display: block;
    border: 1px solid $dark-6;
    border-radius: 100px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 14px;
      height: 14px;
      background: $white;
      border-radius: 50%;
      transition: 0.3s;
    }
  }
}