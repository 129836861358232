.pagination {
  font-family: $headings-font-family;
  display: flex;
  list-style: none;
  cursor: pointer;
  font-size: 1.4rem;
  justify-content: flex-start;
  padding: 2rem 1rem;
  margin: 0;

  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  li {
    margin: 0;
    a {
      color: rgba($black, 0.4);
      padding: 8px;
      border-radius: 5px;
      background: transparent;

      &.pagination_link {
        font-weight: 600;
        color: $black;
      }
    }
  }
}

.pagination_link-active {
  a {
    color: $black;
    background: transparent;
    font-weight: 600;
  
    &:hover {
      color: $black;
    }
  }
}

.pagination_link-disabled {
  a {
    color: $gray;
  }
}
